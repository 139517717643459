.cross-fade {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-content: center;
}
.cross-fade-enter {
  opacity: 0;
}
.cross-fade-enter-active {
  opacity: 1;
  transition: opacity 0.4s;
}
.cross-fade-exit {
  opacity: 1;
}
.cross-fade-exit-active {
  opacity: 0;
  transition: opacity 0.4s;
}

.grow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-content: center;
}
.grow-enter {
  transform: scale(0);
  opacity: 0;
}
.grow-enter-active {
  transform: scale(1);
  opacity: 1;
  transition: opacity 0.4s, transform 0.4s;
}
.grow-exit {
  transform: scale(1);
  opacity: 1;
}
.grow-exit-active {
  transform: scale(10);
  opacity: 0;
  transition: opacity 0.4s, transform 0.4s;
}

.from-bottom {}
.from-bottom-enter {
  transform: translateY(100%);
}
.from-bottom-enter-active {
  transform: translateY(0);
  transition: transform 0.4s;
}
.from-bottom-exit {
  transform: translateY(0);
}
.from-bottom-exit-active {
  transform: translateY(100%);
  transition: transform 0.4s;
}

.from-left {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-content: center;
}
.from-left-enter {
  transform: translateX(-100%);
}
.from-left-enter-active {
  transform: translateX(0);
  transition: transform 0.4s;
}
.from-left-exit {
  transform: translateX(0);
}
.from-left-exit-active {
  transform: translateX(100%);
  transition: transform 0.4s;
}

.from-left-fade {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-content: center;
}
.from-left-fade-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.from-left-fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.5s, transform 0.4s;
}
.from-left-fade-exit {
  opacity: 1;
  transform: translateX(0);
}
.from-left-fade-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.5s, transform 0.4s;
}

.from-right {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-content: center;
}
.from-right-enter {
  transform: translateX(100%);
}
.from-right-enter-active {
  transform: translateX(0);
  transition: transform 0.4s;
}
.from-right-exit {
  //transform: translateX(0);
  opacity: 1;
}
.from-right-exit-active {
  //transform: translateX(100%);
  opacity: 0;
  transition: opacity 0.4s;
}

.from-top {}
.from-top-enter {
  transform: translateY(-100%);
}
.from-top-enter-active {
  transform: translateY(0);
  transition: transform 0.4s;
}
.from-top-exit {
  transform: translateY(0);
}
.from-top-exit-active {
  transform: translateY(-100%);
  transition: transform 0.4s;
}
